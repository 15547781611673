/* eslint-disable */
import OprationPassword from './oprationPassword'

// const components = {
//   BiuButton,
//   BiuInput
// }

const commonComs = {
  OprationPassword
}

// 两种全局注册组件的方法 vue.use() vue.component() 前提是必须要有一个install()方法
const install = Vue => { // main.js 中使用 Vue.use() 安装全部组件
  if (install.installed) return // 判断是否安装
  install.installed = true // 记录安装状态
  // 遍历公共组件
  Object.keys(commonComs).forEach(key => Vue.use(commonComs[key]))
  // 遍历所有组件
  // Object.keys(components).forEach(key => Vue.component(key, components[key]))
}

export default {
  version: '0.1.0',
  install
}
