import Vue from 'vue'
import oprationPassword from './src/index.vue'

const alert = Vue.extend(oprationPassword) // 创建alert组件的构造类

const alertFun = function (options) { // 接收配置
  let str_num = (typeof options === 'string' || typeof options === 'number')
  const Instance = new alert({ // 实例化组件
    data: { // 给data的变量赋值
      title: (options && options.title) || 'Noted',
      text: str_num ? options : ((options && options.text) || ''),
      type: (options && options.type) || 'text',
      cancelText: (options && options.cancelText) || 'Cancel',
      confirmText: (options && options.confirmText) || 'Confirm',
      showConfirm: (options && options.showConfirm !== undefined) ? options.showConfirm : true,
      showCancel: (options && options.showCancel !== undefined) ? options.showCancel : true,
    }
  })
  let vm = Instance.$mount() // 挂载
  document.body.appendChild(vm.$el) // 插入body
  return vm.init() // 执行初始化方法, 返回的是一个promise
}

export default {
  install: (Vue) => { // 暴露install方法供Vue.use()调用
    Vue.prototype.$oprationPassword = alertFun // 挂到Vue的原型上使用
  }
}
