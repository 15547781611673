  import Vue from 'vue'

import VueAMap from 'vue-amap'; // 高德地图

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n

import '@/styles/index.scss' // global css


import App from './App'
import router from './router'
import store from './store'

import '@/icons' // icon
import '@/permission' // permission control

Vue.use(ElementUI, { locale })

Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'e189ae2e6e040e0861df1281cb162f6b',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation','AMap.Marker'],
  uiVersion: '1.0', // ui库版本，不配置不加载,
  v: '1.4.10' // 默认高德 sdk 版本为 1.4.4
});

Vue.config.productionTip = false

import UploadOne from '@/components/UploadOne'
import UploadList from '@/components/UploadList'
Vue.component("upload-one",UploadOne);
Vue.component("upload-list",UploadList);

// https://juejin.cn/post/6844904081085956109
// 自定义Alert 用法this.$alert('').then(()=>{})
import packages from './packages'
Vue.use(packages)

// 图片预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    inline: false,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source'
  }
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
