<template>
  <div class="alertView">
    <transition name="bounce">
      <div v-if="show" class="box">
        <div class="title">操作密码</div>
        <div class="custom">
          <el-input v-model="password" type="password"></el-input>
        </div>
        <div v-if="showConfirm || showCancel" class="bottom">
          <div v-if="showCancel" class="button" @click.stop="handelCancel">取消</div>
          <div v-if="showConfirm" class="button" @click.stop="handelConfirm">确认</div>
        </div>
      </div>
    </transition>
	</div>

</template>

<script>
	export default {
    name: 'OprationPassword',
    data () {
      return {
        title: 'Opration Password',
        text: 'text',
        type: 'text',
        promise: null,
        show: false,
        cancelText: 'Cancel',
        confirmText: 'Confirm',
        showConfirm: true,
        showCancel: true,
        password: '',
      }
    },
    methods: {
      init () { // 初始化, 返回一个promise
        this.show = true
        return new Promise((resolve, reject) => {
          this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
        })
      },
      handelCancel () { // 取消
        this.promise.reject('reject')
        this.destroyVm()
      },
      handelConfirm () { // 确定
        if (!this.password) {
          this.$message({
            type: "warning",
            message: "操作密码不能为空"
          });
          return;
        }
        this.promise.resolve({password: this.password})
        this.destroyVm()
      },
      destroyVm () { // 销毁
        this.show = false
        setTimeout(() => { // 动画完成后执行
          this.$destroy(true) // 完全销毁一个实例。清理它与其它实例的连接，解绑它的全部指令及事件监听器
          this.$el && this.$el.parentNode.removeChild(this.$el) // 从dom节点删除
        }, 100)
      }
    }
	}
</script>

<style lang="scss">
.bounce-enter-active {
  animation: bounce-in .5s;
  // transform-origin: top center;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  // 0% {
  //   transform: scale(1);
  // }
  // 50% {
  //   transform: scale(1.1);
  // }
  // 100% {
  //   transform: scale(1);
  // }
  0% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0.5, 0.5, 0.5);
    opacity: 0;
  }
  50% {
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 1;
  }
}
.alertView {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  .box {
    z-index: inherit;
    width: 85%;
    background-color: #ffffff;
    border-radius: 10px;
    max-width: 400px;
    margin: 0 auto;
    .title {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      padding-top: 20px;
    }
    .text {
      padding: 20px 15px 20px 15px;
      font-size: 14px;
      word-wrap: break-word;
      min-height: 40px;
      text-align: center;
      max-height: 60vh;
      white-space: pre-wrap;
    }
    .richtext {
      padding: 20px 15px 20px 15px;
      font-size: 14px;
      min-height: 40px;
      max-height: 60vh;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    ::-webkit-scrollbar {
        width: 0px;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    .custom {
      padding: 15px 20px;
    }
    .bottom {
      width: 100%;
      border-top: 1px solid #F8F8FA;
      display: flex;
      align-content: center;
      justify-content: center;
      .button {
        flex: 1 1 auto;
        text-align: center;
        font-size: 14px;
        padding: 16px 0;
      }
      .button + .button {
        border-left: 1px solid #F8F8FA;
        box-sizing: border-box;
      }
      .button:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
